import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { firebaseConfig } from '../../RAFFirebase';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import { ContentLibraryRow } from '../../RAFModules/ActiveContacts/Document/Library/ContentLibraryRow';

export class ContentLibraryRepository {

    private myContentLibraryRxReplicationState = null;

    private static RAFContentLibrarySchema = {
        title: 'content_library',
        description: 'describes a Content Library',
        version: 0,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            //file: { type: 'string' },
        },
        required: [
            'UID',
        ],
    };

    public static async createContentLibraryTable(db) {
        if (!db.content_library) {
            await db.addCollections({
                content_library: {
                    schema: this.RAFContentLibrarySchema,
                }
            });
        }
        return db;
    }

    public async insertContentLibrary(contentLibraryDto: ContentLibraryRow) {
        //const db = await this.createContentLibraryTable();
        const db = await RAFDatabase.get();
        contentLibraryDto = RAFDatabase.UpdateRecordInfo('Added', contentLibraryDto);
        const newDocument = await db.content_library.insert(contentLibraryDto);
        return contentLibraryDto;
    }


}