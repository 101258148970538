import { addRxPlugin, createRxDatabase, isRxDocument } from "rxdb";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
// import {
//     RAFViewSchema
// } from './RAFSchemas/RAFViewSchema';

//import { replicateCouchDB } from 'rxdb/plugins/replication-couchdb';

import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration-schema";

import moment from "moment";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { msalInstance } from "..";
import {
  getPureSubDomainOrHint,
  Guid,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
} from "../RAFComponents/helpers/utils";

import { lastOfArray } from "rxdb";
import { replicateRxCollection } from "rxdb/plugins/replication";
import { Constants, RAFHeaderNames } from "../constants/Common/Constants";
//import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBAttachmentsPlugin } from "rxdb/plugins/attachments";
import { RxDBCleanupPlugin } from "rxdb/plugins/cleanup";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
if (process.env.NODE_ENV !== "production") {
  addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBAttachmentsPlugin);
addRxPlugin(RxDBCleanupPlugin);

export interface QueryParams {
  [key: string]: any;
}

//const syncURL = 'http://' + window.location.hostname + ':10102/';
////console.log('host: ' + syncURL);

let dbPromise = null;

const _create = async (tenantName) => {
  //console.log("DatabaseService: creating database..");
  // if (process.env.NODE_ENV !== "production") {
  //     await import('rxdb/plugins/dev-mode').then(
  //         module => addRxPlugin(module as any)
  //     );
  // }

  const db = await createRxDatabase({
    name: "workesioreactdb27042024" + tenantName,
    storage: getRxStorageDexie(),
    multiInstance: false,
    ignoreDuplicate: true,
    cleanupPolicy: {
      /**
       * The minimum time in milliseconds for how long
       * a document has to be deleted before it is
       * purged by the cleanup.
       * [default=one month]
       */
      minimumDeletedTime: 1000 * 60 * 60 * 24 * 31, // one month,
      /**
       * The minimum amount of that that the RxCollection must have existed.
       * This ensures that at the initial page load, more important
       * tasks are not slowed down because a cleanup process is running.
       * [default=60 seconds]
       */
      minimumCollectionAge: 1000 * 60, // 60 seconds
      /**
       * After the initial cleanup is done,
       * a new cleanup is started after [runEach] milliseconds
       * [default=5 minutes]
       */
      runEach: 1000 * 60 * 5, // 5 minutes
      /**
       * If set to true,
       * RxDB will await all running replications
       * to not have a replication cycle running.
       * This ensures we do not remove deleted documents
       * when they might not have already been replicated.
       * [default=true]
       */
      awaitReplicationsInSync: true,
      /**
       * If true, it will only start the cleanup
       * when the current instance is also the leader.
       * This ensures that when RxDB is used in multiInstance mode,
       * only one instance will start the cleanup.
       * [default=true]
       */
      waitForLeadership: true,
    },
  });
  //console.log("DatabaseService: created database");
  window["db"] = db; // write to window for debugging

  // // show leadership in title
  // db.waitForLeadership().then(() => {
  //     //console.log('isLeader now');
  //     //document.title = '♛ ' + document.title;
  // });

  // create collections
  ////console.log('DatabaseService: create collections');
  // await db.addCollections({
  //     rafViews: {
  //         schema: RAFViewSchema,
  //         methods: {
  //             hpPercent() {
  //                 return this.hp / this.maxHP * 100;
  //             }
  //         }
  //     }
  // });

  // hooks
  ////console.log('DatabaseService: add hooks');
  //     db.collections.rafViews.preInsert(docObj => {
  //     const { color } = docObj;
  //     return db.collections.rafViews.findOne({
  //         selector: { color }
  //     }).exec().then(has => {
  //         if (has !== null) {
  //             //console.error('another hero already has the color ' + color);
  //             throw new Error('color already there');
  //         }
  //         return db;
  //     });
  // });

  // sync
  ////console.log('DatabaseService: sync');
  // await Promise.all(
  //     Object.values(db.collections).map(async (col) => {
  //         try {
  //             // create the CouchDB database
  //             await fetch(
  //                 syncURL + col.name + '/',
  //                 {
  //                     method: 'PUT'
  //                 }
  //             );
  //         } catch (err) { }
  //     })
  // );
  ////console.log('DatabaseService: sync - start live');
  // Object.values(db.collections).map(col => col.name).map(colName => {
  //     const url = syncURL + colName + '/';
  //     //console.log('url: ' + url);
  //     // const replicationState = replicateCouchDB({
  //     //     collection: db[colName],
  //     //     url,
  //     //     live: true,
  //     //     pull: {},
  //     //     push: {},
  //     //     autoStart: true
  //     // });
  //     // replicationState.error$.subscribe(err => {
  //     //     //console.log('Got replication error:');
  //     //     //console.dir(err);
  //     // });
  // });

  return db;
};

export const get = () => {
  const tenantName = IsNullOrWhiteSpace(msalInstance.currentTenantName)
    ? ""
    : msalInstance.currentTenantName.toLowerCase().replaceAll(/\s/g, "");
  if (!dbPromise) dbPromise = _create(tenantName);
  return dbPromise;
};

//delete all tables in db
export const deleteAllTables = async () => {
  const db = await get();
  await db.destroy();
};

// export const schemaExist = (schemaName) => {
//   if (!dbPromise) dbPromise = _create();
//   if (dbPromise[schemaName]) return true;
//   return false;
// };

export const UpdateRecordInfo = (
  entityState: "Added" | "Modified" | "Deleted",
  recordObject
) => {
  const objCurrentUser = msalInstance.currentUser;
  if (isNullOrUndefined(recordObject.ModifiedBy))
    recordObject.ModifiedBy = objCurrentUser.DisplayName;
  if (isNullOrUndefined(recordObject.ModifiedByUID))
    recordObject.ModifiedByUID = objCurrentUser.UserUID;
  if (isNullOrUndefined(recordObject.ModifiedDate))
    recordObject.ModifiedDate = moment.utc().format();
  if (isNullOrUndefined(recordObject.RecordOwner))
    recordObject.RecordOwner = objCurrentUser.DisplayName;
  if (isNullOrUndefined(recordObject.RecordOwnerUID))
    recordObject.RecordOwnerUID = objCurrentUser.UserUID;

  if (isNullOrUndefined(recordObject.TenantId))
    recordObject.TenantId = msalInstance.currentTenantId;
  if (isNullOrUndefined(recordObject.TenantUID))
    recordObject.TenantUID = msalInstance.currentTenantId;
  if (isNullOrUndefined(recordObject.LastActivityDate))
    recordObject.LastActivityDate = moment.utc().format();

  recordObject.UpdatedAt = new Date().getTime(); //this column is for sync logic

  switch (entityState) {
    case "Deleted":
      recordObject.IsDeleted = true;
      break;
    case "Modified":
      if (isNullOrUndefined(recordObject.CreatedDate))
        recordObject.CreatedDate = moment.utc().format();
      if (isNullOrUndefined(recordObject.CreatedBy))
        recordObject.CreatedBy = objCurrentUser.DisplayName;
      if (isNullOrUndefined(recordObject.CreatedByUID))
        recordObject.CreatedByUID = objCurrentUser.UserUID;
      break;
    case "Added":
      if (isNullOrUndefined(recordObject.CreatedDate))
        recordObject.CreatedDate = moment.utc().format();
      if (isNullOrUndefined(recordObject.CreatedBy))
        recordObject.CreatedBy = objCurrentUser.DisplayName;
      if (isNullOrUndefined(recordObject.CreatedByUID))
        recordObject.CreatedByUID = objCurrentUser.UserUID;
      recordObject.IsDeleted = false;
      break;
    default:
      break;
  }

  return recordObject;
};

export const initializeRAFDB = () => {
  return new Promise<boolean>(async (resolve, reject) => {
    const db = await get();
    // await deleteDatabase();//temporary line to fix rxdb plugin update issue

    // // await EntityRepository.createRAFEntityTable(db);
    // // await ViewAttributesRepository.createRAFViewAttributeTable(db);
    // // await FormLibraryRepository.createRAFFormLibraryTable(db);
    // // await PageLayoutRepository.createRAFPageLayoutTable(db);
    // // await ContentLibraryRepository.createContentLibraryTable(db);
    // await BpTemplateRepository.createRAFBpTemplateTable(db);
    // await BpStepTemplateRepository.createRAFBusinessProcessStepTemplateTable(db);
    // await BusinessProcessRepository.createRAFBusinessProcessTable(db);
    // await BusinessProcessStepRepository.createRAFBusinessProcessStepTable(db);
    //resolve(true);
    resolve(db);
  });
};

export const createTableByModuleName = async (db, moduleName) => {
  //const db = await RAFDatabase.get();

  if (!db[moduleName]) {
    const objSchema = {
      title: moduleName,
      description: "describes " + moduleName,
      version: 0,
      primaryKey: "UID",
      type: "object",
      properties: {
        UID: { type: "string", maxLength: 100 }, // <- the primary key must have set maxLength
        IsDeleted: { type: "boolean" },
        UpdatedAt: {
          type: "number",
          minimum: 0,
          maximum: 1000000000000000,
          multipleOf: 1,
        },
      },
      indexes: ["UpdatedAt"],
      required: ["UID", "UpdatedAt"],
      attachments: {
        encrypted: false,
      },
      additionalProperties: true,
    };
    try {
      await db.addCollections({
        [moduleName]: {
          schema: objSchema,
        },
      });

      db[moduleName].preSave(function (docData) {
        docData.UpdatedAt = new Date().getTime();
        docData.LastActivityDate = new Date();
      });
    } catch (ex) {
      //console.log('createTableByModuleName error', ex);
    }
  }
  //this.replicateFirestoreForAttribute(db);
  return db;
};

export const syncDataByModuleName = async (db, moduleName) => {
  db = await createTableByModuleName(db, moduleName);
  const replicationState = await replicateRxCollection({
    collection: db[moduleName],
    /**
     * An id for the replication to identify it
     * and so that RxDB is able to resume the replication on app reload.
     * If you replicate with a remote server, it is recommended to put the
     * server url into the replicationIdentifier.
     */
    replicationIdentifier: `my-rest-replication-to-${moduleName}`,
    /**
     * By default it will do an ongoing realtime replication.
     * By settings live: false the replication will run once until the local state
     * is in sync with the remote state, then it will cancel itself.
     * (optional), default is true.
     */
    live: false,
    /**
     * Time in milliseconds after when a failed backend request
     * has to be retried.
     * This time will be skipped if a offline->online switch is detected
     * via navigator.onLine
     * (optional), default is 5 seconds.
     */
    retryTime: 5 * 1000,
    /**
     * When multiInstance is true, like when you use RxDB in multiple browser tabs,
     * the replication should always run in only one of the open browser tabs.
     * If waitForLeadership is true, it will wait until the current instance is leader.
     * If waitForLeadership is false, it will start replicating, even if it is not leader.
     * [default=true]
     */
    waitForLeadership: true,
    /**
     * If this is set to false,
     * the replication will not start automatically
     * but will wait for replicationState.start() being called.
     * (optional), default is true
     */
    autoStart: false,

    /**
     * Custom deleted field, the boolean property of the document data that
     * marks a document as being deleted.
     * If your backend uses a different fieldname then '_deleted', set the fieldname here.
     * RxDB will still store the documents internally with '_deleted', setting this field
     * only maps the data on the data layer.
     *
     * If a custom deleted field contains a non-boolean value, the deleted state
     * of the documents depends on if the value is truthy or not. So instead of providing a boolean * * deleted value, you could also work with using a 'deletedAt' timestamp instead.
     *
     * [default='_deleted']
     */
    deletedField: "deleted",

    /**
     * Optional,
     * only needed when you want to replicate local changes to the remote instance.
     */
    push: {
      /**
       * Push handler
       */
      async handler(docs) {
        /**
         * Push the local documents to a remote REST server.
         */
        const authToken = await msalInstance.getADToken();
        const bearer = `Bearer ${authToken}`;
        let url = `${Constants.baseAPIUrl}Sync/PushAccounts`;

        const rawResponse = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: bearer,
            [RAFHeaderNames.BusinessUnitUID]:
              msalInstance.currentBusinessUnitId,
            [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ docs }),
        });
        /**
         * Contains an array with all conflicts that appeared during this push.
         * If there were no conflicts, return an empty array.
         */
        const response = await rawResponse.json();
        return response;
      },
      /**
       * Batch size, optional
       * Defines how many documents will be given to the push handler at once.
       */
      batchSize: 5,
      /**
       * Modifies all documents before they are given to the push handler.
       * Can be used to swap out a custom deleted flag instead of the '_deleted' field.
       * (optional)
       */
      modifier: (d) => d,
    },
    /**
     * Optional,
     * only needed when you want to replicate remote changes to the local state.
     */
    pull: {
      /**
       * Pull handler
       */
      async handler(lastCheckpoint, batchSize) {
        const minTimestamp = lastCheckpoint ? lastCheckpoint["UpdatedAt"] : 0;
        /**
         * In this example we replicate with a remote REST server
         */
        // const response = await fetch(
        //   `https://example.com/api/sync/?minUpdatedAt=${minTimestamp}&limit=${batchSize}`
        // );

        const data = {
          Checkpoint: lastCheckpoint,
          limit: batchSize,
        };

        const authToken = await msalInstance.getADToken();
        const bearer = `Bearer ${authToken}`;
        let url = `${Constants.baseAPIUrl}Sync/PullAccounts`;

        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          //mode: "cors", // no-cors, *cors, same-origin
          //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          headers: {
            Authorization: bearer,
            [RAFHeaderNames.BusinessUnitUID]:
              msalInstance.currentBusinessUnitId,
            [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });

        const documentsFromRemote = await response.json();
        const documentsFromRemote1 = isNotNullAndUndefined(documentsFromRemote)
          ? documentsFromRemote["Documents"]
          : null;
        return {
          /**
           * Contains the pulled documents from the remote.
           * Notice: If documentsFromRemote.length < batchSize,
           * then RxDB assumes that there are no more un-replicated documents
           * on the backend, so the replication will switch to 'Event observation' mode.
           */
          //documents: documentsFromRemote,
          documents: documentsFromRemote1,
          /**
           * The last checkpoint of the returned documents.
           * On the next call to the pull handler,
           * this checkpoint will be passed as 'lastCheckpoint'
           */
          // checkpoint: documentsFromRemote.length === 0 ? lastCheckpoint : {
          //   UID: lastOfArray(documentsFromRemote)['UID'],
          //   UpdatedAt: lastOfArray(documentsFromRemote)['UpdatedAt']
          // }
          checkpoint:
            isNullOrUndefined(documentsFromRemote1) ||
            documentsFromRemote1.length === 0
              ? lastCheckpoint
              : {
                  UID: lastOfArray(documentsFromRemote1)["UID"],
                  UpdatedAt: lastOfArray(documentsFromRemote1)["UpdatedAt"],
                },
        };
      },
      batchSize: 10,
      /**
       * Modifies all documents after they have been pulled
       * but before they are used by RxDB.
       * (optional)
       */
      modifier: (d) => d,
      /**
       * Stream of the backend document writes.
       * See below.
       * You only need a stream$ when you have set live=true
       */
      //stream$: pullStream$.asObservable()
    },
  });

  // show replication-errors in logs
  replicationState.error$.subscribe((err) => {
    //console.error('replication error:');
    //console.dir(err);
  });

  return replicationState;
};

export const getAllDataByModuleName = (moduleName?: string) => {
  return new Promise<any>(async (resolve, reject) => {
    let db = await get();
    db = await createTableByModuleName(db, moduleName);
    //console.log('getAllDataByModuleName 1', moduleName);
    const objData = await db[moduleName]
      .find({
        selector: {
          IsDeleted: {
            $eq: false,
          },
        },
      })
      .exec();

    let allData = [];
    objData &&
      objData.map((item) => {
        if (isNotNullAndUndefined(item)) {
          allData.push({ ...item.toJSON(), IsOffline: true });
        }
      });
    //console.log('getAllDataByModuleName 2', allData);

    resolve(allData);
  });
};

export const retrieveObjectByUID = (
  UID?: string,
  moduleName?: string,
  queryParams?: QueryParams
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      let db = await get();
      //db = await createTableByModuleName(db, moduleName);
      ////console.log('retrieveObjectByUID 1', moduleName);

      if (db[moduleName]) {
        const rafObject = await db[moduleName]
          .findOne({
            selector: {
              ...(queryParams || {}),
              UID: UID,
            },
          })
          .exec();
        //console.log('retrieveObjectByUID 2', rafObject);
        const is = isRxDocument(rafObject);
        if (is) {
          resolve(rafObject.toJSON());
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    } catch (ex) {
      console.log("retrieveObjectByUID exception", ex);
      resolve(null);
    }
  });
};

export const saveDataByModuleName = (moduleName?: string, rafObject?: any) => {
  return new Promise<any>(async (resolve, reject) => {
    let db = await get();
    db = await createTableByModuleName(db, moduleName);
    //console.log('saveDataByModuleName 1', moduleName, rafObject);
    if (IsNullOrWhiteSpace(rafObject.UID) || isNullOrUndefined(rafObject.UID)) {
      rafObject = await insertDataByModuleName(moduleName, rafObject);
      resolve(rafObject);
    } else {
      //check document exist and insert or update

      const rafObject1 = await db[moduleName]
        .findOne({
          selector: {
            UID: rafObject.UID,
          },
        })
        .exec();
      //console.log('saveDataByModuleName 2', rafObject1);
      const is = isRxDocument(rafObject1);
      //console.log('saveDataByModuleName 3', is);
      if (is) {
        //update
        rafObject = await updateRxDocumentData(rafObject1, rafObject);
        resolve(rafObject);
      } else {
        //insert
        rafObject = await insertDataByModuleName(moduleName, rafObject);
        resolve(rafObject);
      }
    }
  });
};

const insertDataByModuleName = (moduleName?: string, rafObject?: any) => {
  return new Promise<any>(async (resolve, reject) => {
    let db = await get();
    db = await createTableByModuleName(db, moduleName);
    //console.log('insertDataByModuleName 1', moduleName);

    rafObject = UpdateRecordInfo("Added", rafObject);
    //console.log('insertDataByModuleName 2', rafObject);
    if (IsNullOrWhiteSpace(rafObject.UID) || isNullOrUndefined(rafObject.UID)) {
      rafObject.UID = Guid.newGuid();
    }
    if (isNullOrUndefined(rafObject.IsDeleted)) {
      rafObject.IsDeleted = false;
    }
    rafObject.IsOffline = true;
    rafObject.Synced = false;
    const newDocument = await db[moduleName].insert(rafObject);

    resolve(rafObject);
  });
};

const updateRxDocumentData = (rxDocument?: any, rafObject?: any) => {
  return new Promise<any>(async (resolve, reject) => {
    //console.log('updateRxDocumentData 1', rxDocument, rafObject);
    try {
      //rafObject = UpdateRecordInfo('Added', rafObject);
      await rxDocument.update({
        $set: rafObject,
      });
      resolve(rafObject);
    } catch (error) {
      resolve(null);
    }
  });
};

export const SaveFileToDB = async (
  moduleName?: string,
  UID?: string,
  file?: any
) => {
  const db = await get();
  const formDoc = await db[moduleName]
    .findOne({
      selector: {
        UID: UID,
      },
    })
    .exec();
  if (formDoc) {
    formDoc.update({
      $push: {
        attachments: file,
      },
    });
  }
};

export const deleteDataByUIDModuleName = (
  moduleName?: string,
  UID?: string,
  cleanup: boolean = false
) => {
  return new Promise<boolean>(async (resolve, reject) => {
    const db = await get();
    try {
      //console.log('deleteDataByUIDModuleName 1', moduleName, UID);
      const rafObject = await db[moduleName]
        .findOne({
          selector: {
            UID: UID,
          },
        })
        .exec();

      const isDocument = isRxDocument(rafObject);
      let removedBPStep = false;
      if (isDocument) {
        removedBPStep = await rafObject.remove();
        if (cleanup === true) {
          await db[moduleName].cleanup(0);
        }
        resolve(removedBPStep);
      } else {
        resolve(removedBPStep);
      }
    } catch (error) {
      //console.log('deleteDataByUIDModuleName error', error);
      resolve(false);
    }
  });
};

export function isSerializableForDB(value) {
  return (
    value === null ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "string" ||
    (typeof value === "object" &&
      !(value instanceof Date) &&
      !(value instanceof RegExp) &&
      !(value instanceof Map) &&
      !(value instanceof Set) &&
      !(value instanceof WeakMap) &&
      !(value instanceof WeakSet) &&
      !(value instanceof Promise) &&
      !(value instanceof Function))
  );
}
