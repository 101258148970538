import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { msalInstance } from '../..';
import { firebaseConfig } from '../../RAFFirebase';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import { FormLibraryRow } from '../../RAFComponents/models/Common/FormLibraryRow';

export class FormLibraryRepository {

    private static myFormLibraryRxReplicationState = null;

    private static RAFFormLibrarySchema = {
        title: 'form_library',
        description: 'describes a Form Library',
        version: 0,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            Entity: { type: 'string' },
        },
        required: [
            'UID',
            'Entity'
        ],
    };


    public static async createRAFFormLibraryTable(db) {
        //const db = await RAFDatabase.get();
        if (!db.form_library) {
            await db.addCollections({
                form_library: {
                    schema: this.RAFFormLibrarySchema,
                }
            });
        }
        //this.replicateFirestoreForFormLibrary(db);
        return db;
    }

    public static getAllFormLibrary = () => {
        return new Promise<any>(async (resolve, reject) => {
            let db = await RAFDatabase.get();
            db = await this.createRAFFormLibraryTable(db);
            const objData = await db.form_library.find().exec();

            let allData = [];
            objData && objData.map(item => {
                if (isNotNullAndUndefined(item)) {
                    allData.push({ ...item.toJSON(), IsOffline: true });
                }
            });

            resolve(allData);
        });
    };

    public static async insertRAFFormLibrary(rafFormLibraryDto: FormLibraryRow, updateRecordInfo: boolean = false) {
        // return new Promise<any>(async (resolve, reject) => {
        //const db = await this.createRAFFormLibraryTable();
        const db = await RAFDatabase.get();
        if (updateRecordInfo) {
            rafFormLibraryDto = RAFDatabase.UpdateRecordInfo('Added', rafFormLibraryDto);
        }
        const newDocument = await db.form_library.insert(rafFormLibraryDto);
        return rafFormLibraryDto;
        //    resolve(rafFormLibraryDto);
        //});
    }

    //write a method to delete all records from table
    public static async deleteAllRAFFormLibrary() {
        const db = await RAFDatabase.get();
        await db.form_library.clear();
    }

}