import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { firebaseConfig } from '../../RAFFirebase';
import { BusinessProcessRow } from '../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import { isRxDocument } from 'rxdb';

export class BusinessProcessRepository {

    private myBPRxReplicationState = null;
    //private _replicateFirestore = true;

    private static RAFBusinessProcessSchema = {
        title: 'business_processes',
        description: 'describes a business process',
        version: 1,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            // ProcessName: { type: 'string' },
            // StartMode: { type: 'string' },
            // ExecutionOrder: { type: 'string' },
            // ProcessStatus: { type: 'string' },
            // FormData: { type: 'object' },
            // FormDataModel: { type: 'object' },
            // ProcessStarterUID: { type: 'string' },
            // ProcessStarter: { type: 'string' },
            // StartDate: { type: 'Date' },
            // EndDate: { type: 'Date' },
            // DueDate: { type: 'Date' },
            // StepCount: { type: 'integer' },
            // CompletedCount: { type: 'integer' },
            // BpTemplate: { type: 'string' },
            // BpTemplateUID: { type: 'string' },
            // CommentCount: { type: 'integer' },
            // RelatedTo: { type: 'string' },
            // RelatedToUID: { type: 'string' },
            // RelatedToType: { type: 'string' },
            // Description: { type: 'string' },
            // TagsListJson: {
            //     type: 'array', 
            //     "items": {
            //         type: "string",
            //     }
            // },
            // FormType: { type: 'string' },
            // FormName: { type: 'string' },
            // FormUID: { type: 'string' },
            // IconName: { type: 'string' },
            // VersionUID: { type: 'string' },
            // VersionNumber: { type: 'integer' },
            // BusinessProcessType: { type: 'string' },
            // BusinessProcessTypeUID: { type: 'string' },
            // ThemeTemplate: { type: 'string' },
            // CreatedDate: { type: 'Date' },
            // CreatedByUID: { type: 'string' },
            // CreatedBy: { type: 'string' },
            // ModifiedDate: { type: 'Date' },
            // ModifiedByUID: { type: 'string' },
            // ModifiedBy: { type: 'string' },
            // RecordOwner: { type: 'string' },
            // RecordOwnerUID: { type: 'string' },
            // LastActivityDate: { type: 'Date' },
        },
        required: [
            'UID',
        ],
    };

    // constructor(replicateFirestore: boolean = true) {
    //     this._replicateFirestore = replicateFirestore;
    //     this.init();
    // }

    // private async init() {
    //     const db = await this.createRAFBusinessProcessTable();
    //     this.myBPRxReplicationState = await this.replicateFirestoreForBPTemplate();
    // }

    public static async createRAFBusinessProcessTable(db) {
        //const db = await RAFDatabase.get();
        if (!db.business_processes) {
            await db.addCollections({
                business_processes: {
                    schema: this.RAFBusinessProcessSchema,
                    migrationStrategies: {
                        // 1 means, this transforms data from version 0 to version 1
                        1: function (oldDoc) {
                            const newDoc = { ...oldDoc };
                            newDoc.Category = oldDoc.BusinessProcessType;
                            newDoc.CategoryUID = oldDoc.BusinessProcessTypeUID;
                            delete newDoc.BusinessProcessType;
                            delete newDoc.BusinessProcessTypeUID;
                            return newDoc;
                        }
                    }
                }
            });
        }
        return db;
    }

    public async insertRAFBusinessProcess(rafBusinessProcessDto: BusinessProcessRow) {
        //const db = await this.createRAFBusinessProcessTable();
        const db = await RAFDatabase.get();
        rafBusinessProcessDto = RAFDatabase.UpdateRecordInfo('Added', rafBusinessProcessDto);
        const newDocument = await db.business_processes.insert(rafBusinessProcessDto);
        return rafBusinessProcessDto;
    }

    public async updateRAFBusinessProcess(rafBusinessProcessDto: BusinessProcessRow) {
        //const db = await this.createRAFBusinessProcessTable();
        const db = await RAFDatabase.get();
        try {


            const businessProcessData = await db.business_processes && db.business_processes.findOne(
                {
                    selector: {
                        UID: {
                            $eq: rafBusinessProcessDto.UID
                        }
                    }
                }
            ).exec();
            const isDocument = isRxDocument(businessProcessData);
            if (isDocument) {
                await businessProcessData.update({
                    $set: rafBusinessProcessDto
                });

                return rafBusinessProcessDto;
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    public async getBusinessProcessByUID(bpUID?: string) {
        //const db = await this.createRAFBusinessProcessTable();
        const db = await RAFDatabase.get();
        // console.log('BusinessProcessRepository getBusinessProcessByUID 1');
        try {
            const iBusinessProcessDto = await db.business_processes && db.business_processes.findOne(
                {
                    selector: {
                        UID: bpUID
                    }
                }
            ).exec();

            const isDocument = isRxDocument(iBusinessProcessDto);
            if (isDocument) {


                // console.log('BusinessProcessRepository getBusinessProcessByUID 2', iBusinessProcessDto && iBusinessProcessDto.toJSON() );
                return iBusinessProcessDto.toJSON();//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }
}