import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { firebaseConfig } from '../../RAFFirebase';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { Guid, isNotNullAndUndefined, isNullOrUndefined, IsNullOrWhiteSpace } from '../../RAFComponents/helpers/utils';
import { BusinessProcessStepTemplateRow, IBusinessProcessStepTemplateDto } from '../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow';
import { ConvertBPStepTemplateDtoToBPStepTemplateRow } from '../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BPStepTemplateMappingRegister';
import { isRxDocument } from 'rxdb';

export class BpStepTemplateRepository {

    private myBPStepTemplateRxReplicationState = null;
    //private _replicateFirestore = true;

    private static RAFBusinessProcessStepTemplateSchema = {
        title: 'bp_step_templates',
        description: 'describes a business process template step',
        version: 0,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            BpTemplateUID: { type: 'string' },
            // ProcessName: { type: 'string' },
            // StartMode: { type: 'string' },
            // ExecutionOrder: { type: 'string' },
            // ProcessStatus: { type: 'string' },
            // FormData: { type: 'object' },
            // FormDataModel: { type: 'object' },
            // ProcessStarterUID: { type: 'string' },
            // ProcessStarter: { type: 'string' },
            // StartDate: { type: 'Date' },
            // EndDate: { type: 'Date' },
            // DueDate: { type: 'Date' },
            // StepCount: { type: 'integer' },
            // CompletedCount: { type: 'integer' },
            // BpTemplate: { type: 'string' },
            // CommentCount: { type: 'integer' },
            // RelatedTo: { type: 'string' },
            // RelatedToUID: { type: 'string' },
            // RelatedToType: { type: 'string' },
            // Description: { type: 'string' },
            // TagsListJson: {
            //     type: 'array', 
            //     "items": {
            //         type: "string",
            //     }
            // },
            // FormType: { type: 'string' },
            // FormName: { type: 'string' },
            // FormUID: { type: 'string' },
            // IconName: { type: 'string' },
            // VersionUID: { type: 'string' },
            // VersionNumber: { type: 'integer' },
            // BusinessProcessType: { type: 'string' },
            // BusinessProcessTypeUID: { type: 'string' },
            // ThemeTemplate: { type: 'string' },
            // CreatedDate: { type: 'Date' },
            // CreatedByUID: { type: 'string' },
            // CreatedBy: { type: 'string' },
            // ModifiedDate: { type: 'Date' },
            // ModifiedByUID: { type: 'string' },
            // ModifiedBy: { type: 'string' },
            // RecordOwner: { type: 'string' },
            // RecordOwnerUID: { type: 'string' },
            // LastActivityDate: { type: 'Date' },
        },
        required: [
            'UID',
        ],
    };

    // constructor(replicateFirestore: boolean = false) {
    //     this._replicateFirestore = replicateFirestore;
    //     this.init();
    // }

    // private async init() {
    //     const db = await this.createRAFBusinessProcessStepTemplateTable();
    //     this.myBPStepTemplateRxReplicationState = await this.replicateFirestoreForBPStepTemplate();
    // }

    public static async createRAFBusinessProcessStepTemplateTable(db) {
        //const db = await RAFDatabase.get();
        if (!db.bp_step_templates) {
            try {
                await db.addCollections({
                    bp_step_templates: {
                        schema: this.RAFBusinessProcessStepTemplateSchema,
                    }
                });
            }
            catch (e) {
                console.log('createRAFBusinessProcessStepTemplateTable exception', e);
            }
        }
        return db;
    }

    public async insertRAFBusinessProcessTemplateStep(rafBusinessProcessStepTemplateDto: IBusinessProcessStepTemplateDto) {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        rafBusinessProcessStepTemplateDto = RAFDatabase.UpdateRecordInfo('Added', rafBusinessProcessStepTemplateDto);
        if (IsNullOrWhiteSpace(rafBusinessProcessStepTemplateDto.UID) || isNullOrUndefined(rafBusinessProcessStepTemplateDto.UID)) {
            rafBusinessProcessStepTemplateDto.UID = Guid.newGuid();
        }

        try {
            const newDocument = await db.bp_step_templates && db.bp_step_templates.insert(rafBusinessProcessStepTemplateDto);
            const isDocument = isRxDocument(newDocument);

            if (isDocument) {
                const businessProcessTemplateStepRow: BusinessProcessStepTemplateRow =
                    ConvertBPStepTemplateDtoToBPStepTemplateRow(newDocument.toJSON());
                return businessProcessTemplateStepRow;
            }
            else {
                return null;
            }
        }
        catch (error) {
            console.log('error', error);
            return null;
        }

    }

    public async insertRAFBusinessProcessTemplateSteps(bpStepTemplateRows: BusinessProcessStepTemplateRow[]) {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        if (isNotNullAndUndefined(bpStepTemplateRows)) {
            bpStepTemplateRows.forEach(async (item) => {
                if (IsNullOrWhiteSpace(item.UID) || isNullOrUndefined(item.UID)) {
                    item.UID = Guid.newGuid();
                }
                item = RAFDatabase.UpdateRecordInfo('Added', item);
                const newDocument = await db.bp_step_templates.insert(item);
            });
        }
        return bpStepTemplateRows;
    }

    public async updateRAFBusinessProcessTemplateStep(rafBusinessProcessTemplateStepDto: IBusinessProcessStepTemplateDto) {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        try {
            const bpStepData = await db.bp_step_templates && db.bp_step_templates.findOne(
                {
                    selector: {
                        UID: {
                            $eq: rafBusinessProcessTemplateStepDto.UID
                        }
                    }
                }
            ).exec();
            let businessProcessTemplateStepRow: BusinessProcessStepTemplateRow;
            const isDocument = isRxDocument(bpStepData);

            if (isDocument) {
                await bpStepData.update({
                    $set: rafBusinessProcessTemplateStepDto
                });

                businessProcessTemplateStepRow =
                    ConvertBPStepTemplateDtoToBPStepTemplateRow(rafBusinessProcessTemplateStepDto);
            }
            else {
                businessProcessTemplateStepRow = null;
            }


            return businessProcessTemplateStepRow;
        }
        catch (error) {
            return null;
        }
    }

    public async updateRAFBusinessProcessTemplateStepByField(uid, field, value) {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        try {


            const bpStepData = await db.bp_step_templates && db.bp_step_templates.findOne(
                {
                    selector: {
                        UID: {
                            $eq: uid
                        }
                    }
                }
            ).exec();

            const isDocument = isRxDocument(bpStepData);
            if (isDocument) {


                await bpStepData && bpStepData.update({
                    $set: { [field]: value }
                });

                const businessProcessTemplateStepRow: BusinessProcessStepTemplateRow =
                    ConvertBPStepTemplateDtoToBPStepTemplateRow(bpStepData.toJSON());
                businessProcessTemplateStepRow[field] = value;
                return businessProcessTemplateStepRow;
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    public async deleteRAFBusinessProcessTemplateStep(uid) {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        try {
            const bpStepData = await db.bp_step_templates && db.bp_step_templates.findOne(
                {
                    selector: {
                        UID: {
                            $eq: uid
                        }
                    }
                }
            ).exec();
            const isDocument = isRxDocument(bpStepData);
            let removedBPStep = false;
            if (isDocument) {
                removedBPStep = await bpStepData.remove();
                return removedBPStep;
            }
            else {
                return removedBPStep;
            }


        }
        catch (error) {
            return false;
        }
    }

    public async getRAFBPTemplateStepByUID(templateStepUID?: string)//: IBusinessProcessStepTemplateDto[] 
    {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        //console.log('BpStepTemplateRepository getAllRAFBPTemplateSteps 1',db);

        try {
            const bpTemplateStep = await db.bp_step_templates && db.bp_step_templates.findOne(
                {
                    selector: {
                        UID: {
                            $eq: templateStepUID
                        }
                    }
                }
            ).exec();

            //console.log('BpTemplateRepository getAllRAFBPTemplateSteps 2', bpTemplateStep.toJSON());
            const isDocument = isRxDocument(bpTemplateStep);
            if (isDocument) {
                return bpTemplateStep.toJSON() as BusinessProcessStepTemplateRow;//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    public async getAllRAFBPTemplateSteps(bpTemplateUID?: string)//: IBusinessProcessStepTemplateDto[] 
    {
        //const db = await this.createRAFBusinessProcessStepTemplateTable();
        const db = await RAFDatabase.get();
        //this.myBPStepTemplateRxReplicationState = await this.replicateFirestoreForBPStepTemplate();

        try {
            //console.log('BpStepTemplateRepository getAllRAFBPTemplateSteps 1');
            const allBPTemplateStepsData = await db.bp_step_templates && db.bp_step_templates.find(
                {
                    selector: {
                        BpTemplateUID: {
                            $eq: bpTemplateUID
                        }
                    }
                }
            ).exec();


            const isDocument = isRxDocument(allBPTemplateStepsData);
            if (isDocument) {
                let allBPTemplateSteps: IBusinessProcessStepTemplateDto[] = [];
                if (isNotNullAndUndefined(allBPTemplateStepsData)) {
                    allBPTemplateStepsData && allBPTemplateStepsData.map(item => {
                        allBPTemplateSteps.push(item.toJSON());
                    });
                }

                //console.log('BpTemplateRepository getAllRAFBPTemplateSteps 2', allBPTemplateSteps);
                return allBPTemplateSteps;//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }

    }

}