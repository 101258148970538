import { createRxDatabase, lastOfArray } from 'rxdb';
import * as RAFDatabase from '..';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import { Constants, RAFHeaderNames } from '../../constants/Common/Constants';
import { msalInstance } from '../..';
import { getPureSubDomainOrHint, isNotNullAndUndefined, isNullOrUndefined } from '../../RAFComponents/helpers/utils';

export async function initAppVersion() {
    const db = await RAFDatabase.get();

    const versionSchema = {
        title: 'version',
        description: 'describes a simple version',
        version: 0,
        type: 'object',
        primaryKey: 'UID',
        properties: {
            UID: {
                type: 'string',
                primary: true,
                maxLength: 100
            },
            Version: {
                type: 'string',
            },
            Description: {
                type: 'string',
            },
            AppliedOn: {
                type: 'string',
            },
            IsDeleted: { type: 'boolean' },
            UpdatedAt: {
                type: 'number',
                minimum: 0,
                maximum: 1000000000000000,
                multipleOf: 1
            }
        },
        indexes: [
            'UpdatedAt'
        ],
        required: ['UID', 'UpdatedAt'],
        attachments: {
            encrypted: false
        },
        additionalProperties: true
    };

    if (!db.version) {
        try {
            await db.addCollections({
                'version': {
                    schema: versionSchema,
                }
            });

            db['version'].preSave(function (docData) {
                docData.UpdatedAt = new Date().getTime();
                docData.LastActivityDate = new Date();
            });
        }
        catch (ex) {
            console.log('createTableByModuleName error', ex);
        }



        // // Set up replication
        // // const replicationState = versionCollection.syncCouchDB({
        // //     remote: 'http://localhost:5984/mydatabase', // remote CouchDB database
        // //     options: {
        // //         live: true, // continuous replication
        // //         retry: true // retry on connection loss
        // //     }
        // // });

        // const replicationState = await replicateRxCollection({
        //     collection: db.version,
        //     /**
        //      * An id for the replication to identify it
        //      * and so that RxDB is able to resume the replication on app reload.
        //      * If you replicate with a remote server, it is recommended to put the
        //      * server url into the replicationIdentifier.
        //      */
        //     replicationIdentifier: `my-rest-replication-to-version`,
        //     /**
        //      * By default it will do an ongoing realtime replication.
        //      * By settings live: false the replication will run once until the local state
        //      * is in sync with the remote state, then it will cancel itself.
        //      * (optional), default is true.
        //      */
        //     live: false,
        //     /**
        //      * Time in milliseconds after when a failed backend request
        //      * has to be retried.
        //      * This time will be skipped if a offline->online switch is detected
        //      * via navigator.onLine
        //      * (optional), default is 5 seconds.
        //      */
        //     retryTime: 5 * 1000,
        //     /**
        //      * When multiInstance is true, like when you use RxDB in multiple browser tabs,
        //      * the replication should always run in only one of the open browser tabs.
        //      * If waitForLeadership is true, it will wait until the current instance is leader.
        //      * If waitForLeadership is false, it will start replicating, even if it is not leader.
        //      * [default=true]
        //      */
        //     waitForLeadership: true,
        //     /**
        //      * If this is set to false,
        //      * the replication will not start automatically
        //      * but will wait for replicationState.start() being called.
        //      * (optional), default is true
        //      */
        //     autoStart: false,

        //     /**
        //      * Custom deleted field, the boolean property of the document data that
        //      * marks a document as being deleted.
        //      * If your backend uses a different fieldname then '_deleted', set the fieldname here.
        //      * RxDB will still store the documents internally with '_deleted', setting this field
        //      * only maps the data on the data layer.
        //      * 
        //      * If a custom deleted field contains a non-boolean value, the deleted state
        //      * of the documents depends on if the value is truthy or not. So instead of providing a boolean * * deleted value, you could also work with using a 'deletedAt' timestamp instead.
        //      * 
        //      * [default='_deleted']
        //      */
        //     deletedField: 'deleted',


        //     /**
        //      * Optional,
        //      * only needed when you want to replicate remote changes to the local state.
        //      */
        //     pull: {
        //         /**
        //          * Pull handler
        //          */
        //         async handler(lastCheckpoint, batchSize) {
        //             const minTimestamp = lastCheckpoint ? lastCheckpoint['UpdatedAt'] : 0;
        //             /**
        //              * In this example we replicate with a remote REST server
        //              */
        //             // const response = await fetch(
        //             //   `https://example.com/api/sync/?minUpdatedAt=${minTimestamp}&limit=${batchSize}`
        //             // );

        //             const data = {
        //                 Checkpoint: lastCheckpoint,
        //                 limit: batchSize
        //             };


        //             const authToken = await msalInstance.getADToken();
        //             const bearer = `Bearer ${authToken}`;
        //             let url = `${Constants.baseAPIUrl}VersionInfo/Pull`;

        //             const response = await fetch(url, {
        //                 method: "POST", // *GET, POST, PUT, DELETE, etc.
        //                 //mode: "cors", // no-cors, *cors, same-origin
        //                 //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //                 //credentials: "same-origin", // include, *same-origin, omit
        //                 headers: {
        //                     "Authorization": bearer,
        //                     [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
        //                     [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
        //                     "Content-Type": "application/json",
        //                     // 'Content-Type': 'application/x-www-form-urlencoded',
        //                 },
        //                 redirect: "follow", // manual, *follow, error
        //                 referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //                 body: JSON.stringify(data), // body data type must match "Content-Type" header
        //             });

        //             const documentsFromRemote = await response.json();
        //             const documentsFromRemote1 = isNotNullAndUndefined(documentsFromRemote) ? documentsFromRemote['Documents'] : null;
        //             return {
        //                 /**
        //                  * Contains the pulled documents from the remote.
        //                  * Notice: If documentsFromRemote.length < batchSize,
        //                  * then RxDB assumes that there are no more un-replicated documents
        //                  * on the backend, so the replication will switch to 'Event observation' mode.
        //                  */
        //                 //documents: documentsFromRemote,
        //                 documents: documentsFromRemote1,
        //                 /**
        //                  * The last checkpoint of the returned documents.
        //                  * On the next call to the pull handler,
        //                  * this checkpoint will be passed as 'lastCheckpoint'
        //                  */
        //                 // checkpoint: documentsFromRemote.length === 0 ? lastCheckpoint : {
        //                 //   UID: lastOfArray(documentsFromRemote)['UID'],
        //                 //   UpdatedAt: lastOfArray(documentsFromRemote)['UpdatedAt']
        //                 // }
        //                 checkpoint: isNullOrUndefined(documentsFromRemote1) || documentsFromRemote1.length === 0 ? lastCheckpoint : {
        //                     UID: lastOfArray(documentsFromRemote1)['UID'],
        //                     UpdatedAt: lastOfArray(documentsFromRemote1)['UpdatedAt']
        //                 }
        //             };
        //         },
        //         batchSize: 10,
        //         /**
        //          * Modifies all documents after they have been pulled
        //          * but before they are used by RxDB.
        //          * (optional)
        //          */
        //         modifier: d => d,
        //         /**
        //          * Stream of the backend document writes.
        //          * See below.
        //          * You only need a stream$ when you have set live=true
        //          */
        //         //stream$: pullStream$.asObservable()
        //     },
        // });

        // // // Listen for replication events
        // // replicationState.change$.subscribe(change => {
        // //     if (change.direction === 'pull' && change.doc._id === 'version') {
        // //         // New version detected, trigger an update
        // //         window.location.reload();
        // //     }
        // // });
    }
}