import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { firebaseConfig } from '../../RAFFirebase';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import { BusinessProcessStepRow, IBusinessProcessStepDto } from '../../RAFMaster/RMModules/BusinessProcess/Step/BusinessProcessStepRow';
import { ConvertBPStepDtoToBPStepRow } from '../../RAFMaster/RMModules/BusinessProcess/Step/BPStepMappingRegister';
import { addRxPlugin, isRxDocument } from 'rxdb';
addRxPlugin(RxDBUpdatePlugin);

export class BusinessProcessStepRepository {

    private myBPStepRxReplicationState = null;
    //private _replicateFirestore = true;

    private static RAFBusinessProcessStepSchema = {
        title: 'business_process_steps',
        description: 'describes a business process step',
        version: 0,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            BusinessProcessUID: { type: 'string' },
            // ProcessName: { type: 'string' },
            // StartMode: { type: 'string' },
            // ExecutionOrder: { type: 'string' },
            // ProcessStatus: { type: 'string' },
            // FormData: { type: 'object' },
            // FormDataModel: { type: 'object' },
            // ProcessStarterUID: { type: 'string' },
            // ProcessStarter: { type: 'string' },
            // StartDate: { type: 'Date' },
            // EndDate: { type: 'Date' },
            // DueDate: { type: 'Date' },
            // StepCount: { type: 'integer' },
            // CompletedCount: { type: 'integer' },
            // BpTemplate: { type: 'string' },
            // BpTemplateUID: { type: 'string' },
            // CommentCount: { type: 'integer' },
            // RelatedTo: { type: 'string' },
            // RelatedToUID: { type: 'string' },
            // RelatedToType: { type: 'string' },
            // Description: { type: 'string' },
            // TagsListJson: {
            //     type: 'array', 
            //     "items": {
            //         type: "string",
            //     }
            // },
            // FormType: { type: 'string' },
            // FormName: { type: 'string' },
            // FormUID: { type: 'string' },
            // IconName: { type: 'string' },
            // VersionUID: { type: 'string' },
            // VersionNumber: { type: 'integer' },
            // BusinessProcessType: { type: 'string' },
            // BusinessProcessTypeUID: { type: 'string' },
            // ThemeTemplate: { type: 'string' },
            // CreatedDate: { type: 'Date' },
            // CreatedByUID: { type: 'string' },
            // CreatedBy: { type: 'string' },
            // ModifiedDate: { type: 'Date' },
            // ModifiedByUID: { type: 'string' },
            // ModifiedBy: { type: 'string' },
            // RecordOwner: { type: 'string' },
            // RecordOwnerUID: { type: 'string' },
            // LastActivityDate: { type: 'Date' },
        },
        required: [
            'UID',
        ],
    };

    // constructor(replicateFirestore: boolean = true) {
    //     this._replicateFirestore = replicateFirestore;
    //     this.init();
    // }

    // private async init() {
    //     const db = await this.createRAFBusinessProcessStepTable();
    //     this.myBPStepRxReplicationState = await this.replicateFirestoreForBPStep();
    // }

    public static async createRAFBusinessProcessStepTable(db) {
        //const db = await RAFDatabase.get();
        if (!db.business_process_steps) {
            await db.addCollections({
                business_process_steps: {
                    schema: this.RAFBusinessProcessStepSchema,
                }
            });
        }
        return db;
    }

    public async insertRAFBusinessProcessStep(rafBusinessProcessStepDto: IBusinessProcessStepDto) {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        rafBusinessProcessStepDto = RAFDatabase.UpdateRecordInfo('Added', rafBusinessProcessStepDto);
        const newDocument = await db.business_process_steps.insert(rafBusinessProcessStepDto);
        const businessProcessStepRow: BusinessProcessStepRow =
            ConvertBPStepDtoToBPStepRow(newDocument);
        return businessProcessStepRow;
    }

    public async updateRAFBusinessProcessStep(rafBusinessProcessStepDto: IBusinessProcessStepDto) {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        try {
            const bpStepData = await db.business_process_steps && db.business_process_steps.findOne(
                {
                    selector: {
                        UID: {
                            $eq: rafBusinessProcessStepDto.UID
                        }
                    }
                }
            ).exec();

            const isDocument = isRxDocument(bpStepData);
            if (isDocument) {

                await bpStepData.update({
                    $set: rafBusinessProcessStepDto
                });

                const businessProcessStepRow: BusinessProcessStepRow =
                    ConvertBPStepDtoToBPStepRow(rafBusinessProcessStepDto);
                return businessProcessStepRow;
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

    public async updateRAFBusinessProcessStepByField(uid, field, value) {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        try {
            const bpStepData = await db.business_process_steps && db.business_process_steps.findOne(
                {
                    selector: {
                        UID: {
                            $eq: uid
                        }
                    }
                }
            ).exec();

            const isDocument = isRxDocument(bpStepData);
            if (isDocument) {


                await bpStepData.update({
                    $set: { [field]: value }
                });

                const businessProcessStepRow: BusinessProcessStepRow =
                    ConvertBPStepDtoToBPStepRow(bpStepData.toJSON());
                businessProcessStepRow[field] = value;
                return businessProcessStepRow;
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }


    public async insertRAFBusinessProcessSteps(bpStepRows: BusinessProcessStepRow[]) {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        try {
            if (isNotNullAndUndefined(bpStepRows)) {
                bpStepRows.forEach(async (item) => {
                    item = RAFDatabase.UpdateRecordInfo('Added', item);
                    const newDocument = await db.business_process_steps.insert(item);
                });
            }
            return bpStepRows;
        }
        catch (error) {
            return null;
        }
    }

    public async getRAFBusinessProcessStepByUID(stepUID?: string)//: IBusinessProcessStepTemplateDto[] 
    {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        //console.log('BusinessProcessStepRepository getRAFBusinessProcessStepByUID 1');
        try {
            const businessProcessStep = await db.business_process_steps && db.business_process_steps.findOne(
                {
                    selector: {
                        UID: {
                            $eq: stepUID
                        }
                    }
                }
            ).exec();
            const isDocument = isRxDocument(businessProcessStep);
            if (isDocument) {
                //console.log('BusinessProcessStepRepository getRAFBusinessProcessStepByUID 2',  businessProcessStep && businessProcessStep.toJSON() );
                return businessProcessStep.toJSON();//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }


    public async getAllRAFBPStepsByProcessId(processUID?: string)//: IBusinessProcessStepTemplateDto[] 
    {
        //const db = await this.createRAFBusinessProcessStepTable();
        const db = await RAFDatabase.get();
        //console.log('BusinessProcessStepRepository getAllRAFBPSteps 1');
        try {
            const allBPStepsData = await db.business_process_steps && db.business_process_steps.find(
                {
                    selector: {
                        BusinessProcessUID: {
                            $eq: processUID
                        }
                    }
                }
            ).exec();

            const isDocument = isRxDocument(allBPStepsData);
            if (isDocument) {

                let allBPSteps: IBusinessProcessStepDto[] = [];
                allBPStepsData.map(item => {
                    allBPSteps.push(item.toJSON());
                });
                //console.log('BusinessProcessStepRepository getAllRAFBPSteps 2', allBPSteps);

                return allBPSteps;//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            return null;
        }
    }

}