import { collection } from 'firebase/firestore';
import * as RAFDatabase from '..';
import { firebaseConfig } from '../../RAFFirebase';
import { replicateFirestore } from 'rxdb/plugins/replication-firestore';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import { BusinessProcessTemplateRow, IBusinessProcessTemplateDto } from '../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow';
import { ConvertBPTemplateDtoToBusinessProcessTemplateRow } from '../../RAFMaster/RMModules/BusinessProcess/Template/BPTemplateMappingRegister';
import { isRxDocument } from 'rxdb';

export class BpTemplateRepository {

    private myBPTemplateRxReplicationState = null;
    //private _replicateFirestore = true;

    private static RAFBpTemplateSchema = {
        title: 'bp_templates',
        description: 'describes a business process template',
        version: 0,
        primaryKey: 'UID',
        type: 'object',
        properties: {
            UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
            CategoryUID: { type: 'string' },
            // ProcessName: { type: 'string' },
            // StartMode: { type: 'string' },
            // ExecutionOrder: { type: 'string' },
            // ProcessStatus: { type: 'string' },
            // FormData: { type: 'object' },
            // FormDataModel: { type: 'object' },
            // ProcessStarterUID: { type: 'string' },
            // ProcessStarter: { type: 'string' },
            // StartDate: { type: 'Date' },
            // EndDate: { type: 'Date' },
            // DueDate: { type: 'Date' },
            // StepCount: { type: 'integer' },
            // CompletedCount: { type: 'integer' },
            // BpTemplate: { type: 'string' },
            // BpTemplateUID: { type: 'string' },
            // CommentCount: { type: 'integer' },
            // RelatedTo: { type: 'string' },
            // RelatedToUID: { type: 'string' },
            // RelatedToType: { type: 'string' },
            // Description: { type: 'string' },
            // TagsListJson: {
            //     type: 'array', 
            //     "items": {
            //         type: "string",
            //     }
            // },
            // FormType: { type: 'string' },
            // FormName: { type: 'string' },
            // FormUID: { type: 'string' },
            // IconName: { type: 'string' },
            // VersionUID: { type: 'string' },
            // VersionNumber: { type: 'integer' },
            // BusinessProcessType: { type: 'string' },
            // BusinessProcessTypeUID: { type: 'string' },
            // ThemeTemplate: { type: 'string' },
            // CreatedDate: { type: 'Date' },
            // CreatedByUID: { type: 'string' },
            // CreatedBy: { type: 'string' },
            // ModifiedDate: { type: 'Date' },
            // ModifiedByUID: { type: 'string' },
            // ModifiedBy: { type: 'string' },
            // RecordOwner: { type: 'string' },
            // RecordOwnerUID: { type: 'string' },
            // LastActivityDate: { type: 'Date' },
        },
        required: [
            'UID',
        ],
    };

    // constructor(replicateFirestore: boolean = true) {
    //     this._replicateFirestore = replicateFirestore;
    //     this.init();
    // }

    // private static async init(replicateFirestore: boolean = true) {
    //     //this._replicateFirestore = replicateFirestore;
    //     //const db = await this.createRAFBpTemplateTable();
    //     //const db = await RAFDatabase.get();
    //     if (replicateFirestore) {
    //         this.myBPTemplateRxReplicationState = await this.replicateFirestoreForBPTemplate();
    //     }
    // }

    public static async createRAFBpTemplateTable(db) {
        //const db = await RAFDatabase.get();
        if (!db.bp_templates) {
            await db.addCollections({
                bp_templates: {
                    schema: this.RAFBpTemplateSchema,
                    migrationStrategies: {
                        // 1 means, this transforms data from version 0 to version 1
                        1: function (oldDoc) {
                            const newDoc = { ...oldDoc };
                            newDoc.TemplateType = oldDoc.Category;
                            newDoc.Category = oldDoc.BusinessProcessType;
                            newDoc.CategoryUID = oldDoc.BusinessProcessTypeUID;
                            delete newDoc.BusinessProcessType;
                            delete newDoc.BusinessProcessTypeUID;
                            return newDoc;
                        }
                    }
                }
            });
        }
        return db;
    }

    public async insertRAFBPTemplate(rafBusinessProcessTemplateDto: IBusinessProcessTemplateDto) {
        //const db = await this.createRAFBpTemplateTable();
        const db = await RAFDatabase.get();
        rafBusinessProcessTemplateDto = RAFDatabase.UpdateRecordInfo('Added', rafBusinessProcessTemplateDto);
        const newDocument = await db.bp_templates.insert(rafBusinessProcessTemplateDto);
        return rafBusinessProcessTemplateDto;
    }

    public async updateRAFBusinessProcessTemplateByField(bpTemplate, field, value) {
        //const db = await this.createRAFBpTemplateTable();
        const db = await RAFDatabase.get();
        const bpTemplateData = await db.bp_templates && db.bp_templates.findOne(
            {
                selector: {
                    UID: {
                        $eq: bpTemplate.UID
                    }
                }
            }
        ).exec();

        let businessProcessTemplate: BusinessProcessTemplateRow = {};

        try {
            const isDocument = isRxDocument(bpTemplateData);
            if (isDocument) {
                await bpTemplateData.update({
                    $set: { [field]: value }
                });

                businessProcessTemplate =
                    ConvertBPTemplateDtoToBusinessProcessTemplateRow(bpTemplateData.toJSON());
                businessProcessTemplate[field] = value;
                return businessProcessTemplate;
            }
            else {
                return null;
            }
        }
        catch (error) {
            console.log('error', error);
            return null;
        }


    }


    public async getAllRAFBPTemplates(bpTypeUID?: string)//: IBusinessProcessTemplateDto[] 
    {
        //const db = await this.createRAFBpTemplateTable();
        const db = await RAFDatabase.get();
        console.log('BpTemplateRepository getAllRAFBPTemplates 1');

        try {
            const allBPTemplates = await db.bp_templates && db.bp_templates.find(
                {
                    selector: {
                        CategoryUID: {
                            $eq: bpTypeUID
                        }
                    }
                }
            ).exec();
            const isDocument = isRxDocument(allBPTemplates);
            if (isDocument) {
                //console.log('BpTemplateRepository getAllRAFBPTemplates 2', allBPTemplates && allBPTemplates.toJSON());
                return allBPTemplates.toJSON();//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            console.log('error', error);
            return null;
        }

    }

    public async getRAFBPTemplateByUID(bpTemplateUID?: string)//: IBusinessProcessTemplateDto
    {
        //const db = await this.createRAFBpTemplateTable();
        const db = await RAFDatabase.get();
        console.log('BpTemplateRepository getRAFBPTemplate 1');

        try {
            const bpTemplateDto = await db.bp_templates && db.bp_templates.findOne(
                {
                    selector: {
                        UID: {
                            $eq: bpTemplateUID
                        }
                    }
                }
            ).exec();
            const isDocument = isRxDocument(bpTemplateDto);
            if (isDocument) {
                //console.log('BpTemplateRepository getRAFBPTemplate 2', bpTemplateDto && bpTemplateDto.toJSON());
                return bpTemplateDto.toJSON();//get from storage or else call api
            }
            else {
                return null;
            }
        }
        catch (error) {
            console.log('error', error);
            return null;
        }
    }

}